import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons'; import { Container, Button } from './styles/Footer';
import DropDown, { DropDownContent, DropDownItem } from '../../components/DropDown';
import HR from '../HR';
import applicationInformation from '../../applicationInformation.json';

type FooterButtonProps = {
  onClick?: React.MouseEvent<HTMLButtonElement>,
  children?: React.ReactNode,
  [x: string]: any,
};

export const FooterButton = ({ onClick, children, ...restProps }: FooterButtonProps) => {
  return (
    <Button onClick={onClick} {...restProps}>
      {children}
    </Button>
  );
};

type image = {
  imageInfo: {
    user: {
      name: string,
    },
    referralUrl: string,
    urls: {
      regular: string,
    },
  } | null
};

const Footer = ({ imageInfo = null }: image) => {
  const year = (new Date()).getFullYear();
  return (
    <Container>
      <span>
        &copy; {`${year} ${applicationInformation.copyright}`}
      </span>

      {imageInfo && (
        <DropDown id="soul-inspire-footer-menu" hover>
          <FooterButton id="footer-menu-button" aria-label="Footer Information Details">
            <FontAwesomeIcon icon={faInfo} size="lg" />
          </FooterButton>

          <DropDownContent id="footer-menu-content" anchorEl="footer-menu-button" direction="up">
            <DropDownItem>
              <div className="display-flex flex-direction-column">
                <h4>Credits</h4>
                <span style={{ marginLeft: 5 }}>
                  Background Photo by {imageInfo.user.name} on <a href={imageInfo.referralUrl} target="_new">Unsplash</a>
                </span>
              </div>
            </DropDownItem>

            <HR secondary />

            <DropDownItem>
              <div className="display-flex flex-direction-column">
                <h4>Version</h4>
                <span style={{ marginLeft: 5 }}>
                  {applicationInformation.version}
                </span>
              </div>
            </DropDownItem>
          </DropDownContent>
        </DropDown>
      )}
    </Container >
  );
};

export default Footer;
