import styled from 'styled-components/macro';

type ContainerProps = {
  show: boolean;
};

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(255,255,255, 0.2);
  backdrop-filter: blur(25px);
  visibility: ${({ show }: ContainerProps) => show ? 'visible' : 'hidden'};
  overflow-x: hidden;
  `;

export const Inner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;

  scrollbar-color: transparent transparent;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  @media only screen and (min-width: 1000px) {
    width: 1000px;
  }
`;

export const Close = styled.div.attrs(() => ({
  ariaLabel: 'Close',
}))`
  & {
    position: absolute;
    right: 30px;
    top: 25px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  &::before, &::after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 1px;
    background-color: #fff;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;
