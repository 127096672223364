// Helpful button shadow info: https://medium.com/hellotegra/3-rules-to-perfect-button-shadow-e7227a5ae87d

import styled from 'styled-components/macro';
import { hexToRGB } from '../../../helpers/color.helper';
import colors from '../../../styles/colors';

export interface ButtonProps {
  size?: string
}

export const ButtonStandard = styled.button<ButtonProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 800;
  font-size: 1em;
  text-transform: uppercase;
  color: white;
  border: none;

  //Button shape & animation
  border-radius: 6px;
  margin: 20px 0;
  padding: 1em 3em;
  background-size: 200% auto;
  box-shadow: 0 18px 44px -12px ${hexToRGB(colors.blue, 0.35)};
  background-image: linear-gradient(to right, ${colors.blue} 0%, ${colors.green} 50%, ${colors.lightGreen} 100%);
  transition: 0.5s;

  &:hover {
    background-position: right center;
    box-shadow: 0 18px 44px ${hexToRGB(colors.lightGreen, 0.35)};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.lightGreen};
    background-image: none;
  }

  ${({ size }) => {
    switch (size) {
      case 'lg':
        return `
          padding: 1em 3.5em;
          font-size: 1.25em;
        `;
      case 'xl':
        return `
          padding: 1.1em 3.75em;
          font-size: 1.5em;
        `;
    }
  }}
`;