import React, { FunctionComponent, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Content, Item } from './styles/DropDown';

interface DropDownProps {
  children: ReactNode;
  hover?: boolean;
  [x: string]: any;
};

interface DropDownItemProps extends DropDownProps {
  href?: string;
  to?: string;
  close?: Function;
}

export const DropDownContent: FunctionComponent<DropDownProps> = ({ children, ...restProps }) => {
  return (
    <Content {...restProps}>
      {
        React.Children.map(children, (child: any) => child ? React.cloneElement(child, { close: restProps?.close }) : child)
      }
    </Content>
  );
};

export const DropDownItem: FunctionComponent<DropDownItemProps> = ({ href, to, children, close, ...restProps }) => {
  const link = !!(href) || !!(to);
  const onClick = () => {
    if (restProps?.onClick && typeof restProps.onClick === 'function') {
      restProps.onClick();
    }

    if (close && typeof close === 'function') {
      close();
    }
  };

  return (
    <Item {...restProps} link={link}>
      {href
        ? <a href={href} onClick={onClick} className="menu-link">{children}</a>
        : to
          ? <Link to={to} onClick={onClick} className="menu-link">{children}</Link>
          : children
      }
    </Item>
  );
};

const DropDown: FunctionComponent<DropDownProps> = ({ children, hover, ...restProps }) => {
  const [show, setShow] = useState(false);

  const setShowHelper = () => setShow(!show);
  const options = hover
    ? {
      onMouseEnter: setShowHelper,
      onMouseLeave: setShowHelper,
    }
    : {
      onClick: setShowHelper,
    };
  return (
    <Container {...restProps}>
      {
        React.Children.map(children, (child: any, index) => {
          return child.props.anchorEl
            ? React.cloneElement(child, {
              show,
              index,
              close: setShowHelper,
            })
            : React.cloneElement(child, {
              ...options,
              index
            });
        })
      }
    </Container>
  );
};

export default DropDown;
