import { forwardRef, useImperativeHandle, useState } from 'react';
import { Container, Inner, Content, Close } from './styles/Overlay';

export type OverlayPropType = {
  children: any;
  closeCallback?: Function | null;
  [x: string]: any;
}

const Overlay = forwardRef(({ children, closeCallback, ...rest }: OverlayPropType, ref) => {
  const [visible, setVisible] = useState(false);

  const show = (forceShow: boolean | null = null) => {
    if (forceShow === false || !visible === false) {
      closeCallback && closeCallback();
    }
    setVisible(forceShow === null ? !visible : forceShow);
  };

  useImperativeHandle(
    ref,
    () => ({
      show,
    }),
  );

  return (
    <Container {...rest} show={visible}>
      <Close onClick={() => show(false)} />
      <Inner>
        <Content>
          {children}
        </Content>
      </Inner>
    </Container>
  );
});

export default Overlay;