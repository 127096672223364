import { Wrapper, Container, Quote, Author } from './styles/Quotation';

type QuotationProps = {
  quote?: {
    quote: string | null,
    author: string | null,
  } | null,
  [x: string]: any
};

const defaultQuote = {
  quote: '',
  author: ''
};

export default function Quotation({ quote = defaultQuote, ...restProps }: QuotationProps) {
  return quote
    ? (
      <Wrapper>
        <Container {...restProps}>
          <Quote>
            {quote.quote || ''}
          </Quote>
          <Author>
            {quote.author || ''}
          </Author>
        </Container>
      </Wrapper>
    )
    : <></>;


}