import { TinyColor } from '@ctrl/tinycolor'; // https://www.npmjs.com/package/tinycolor-ts


export function hexToRGB(hex: string, alpha?: number) {
  const color = new TinyColor(hex);
  alpha && color.setAlpha(alpha);
  return color.toRgbString();
}

export function isDark(color: string) {
  return (new TinyColor(color)).isDark();
}

export function isLight(color: string) {
  return (new TinyColor(color)).isLight();
}