import styled from 'styled-components/macro';

type ButtonProps = {
  onClick?: React.MouseEvent<HTMLButtonElement>,
  [x: string]: any,
};

export const Container = styled.footer`
  height: 50px;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-size: 0.75rem;
  font-weight: 200;
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* border: 1px solid rgba(255,255,255, 0.8); */
  border: none;
  background: transparent;
  height: 30px;
  width: 30px;
  /* margin: 10px 20px 0 0; */

  svg {
    color: rgba(255,255,255, 0.8);
  }

  &:hover {
    background: white;
    /* height: 30px; */
    svg {
      color: black;
    }
    cursor: pointer;
  }

  &:active {
    border: none;
    outline: none;
  }

  &:focus {
    outline: none;
  }
`;