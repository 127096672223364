import { Content } from './styles/Title';

interface TitleProps {
  children: string;
};

function Title({ children }: TitleProps) {
  return (
    <Content>{children}</Content>
  );
}

export default Title;