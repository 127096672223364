import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

interface SpinnerInterface {
  size?: 'xs' | 'lg' | 'sm' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x' | undefined;
  color?: string;
};

export default function Spinner({ size, color }: SpinnerInterface) {
  return (
    <FontAwesomeIcon
      icon={faCircleNotch}
      size={size}
      color={color}
      spin
    />
  );
}
