import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faFlask } from '@fortawesome/free-solid-svg-icons';
import DropDown, { DropDownContent, DropDownItem } from '../DropDown';
import HR from '../HR';
import { useUserContext } from '../../hooks/userHook';
import { Container, Button } from './styles/Header';
import siLogo from '../../images/logos/soul-inspire-logo-1-trans.png';

type HeaderButtonProps = {
  onClick?: React.MouseEvent<HTMLButtonElement>,
  children?: React.ReactNode,
  [x: string]: any,
};

export const HeaderButton = ({ onClick, children, ...restProps }: HeaderButtonProps) => {
  return (
    <Button onClick={onClick} {...restProps}>
      {children}
    </Button>
  );
};

export default function Header() {
  const { user, logoutUser } = useUserContext();
  return (
    <Container>
      <a href="/">
        <img src={siLogo} alt="Soul Inspire Logo" />
      </a>
      <DropDown id="soul-inspire-header-menu">
        <HeaderButton id="header-menu-button" aria-label="Top Menu Button">
          <FontAwesomeIcon icon={faBars} size="lg" />
        </HeaderButton>


        <DropDownContent id="header-menu-content" anchorEl="header-menu-button">
          <DropDownItem to="/">Home</DropDownItem>
          <HR secondary />
          {process.env.NODE_ENV === 'development' && <DropDownItem>About Us <i><sup>&nbsp;&nbsp;&nbsp;coming soon!</sup></i></DropDownItem>}
          <DropDownItem to="/image-creator">
            <span style={{ marginRight: 10 }}>
              Image Creator
            </span>
            <FontAwesomeIcon icon={faFlask} aria-label="beta feature" />
          </DropDownItem>
          <HR secondary />
          {
            user
              ? <DropDownItem>
                <span style={{ marginRight: 5, fontSize: '.25em' }}>{user.email}</span>
                <button onClick={logoutUser}>Logout</button>
              </DropDownItem>
              : <DropDownItem to='/sign-in'>Sign In</DropDownItem>
          }
        </DropDownContent>
      </DropDown>
    </Container>
  );
}
