import { ReactNode } from 'react';
import { ButtonStandard } from './styles/ButtonStandard';
import { ButtonCircle as Circle } from './styles/ButtonCircle';

interface ButtonProps {
  children: ReactNode,
  color?: string,
  size?: string,
  [x: string]: any
}

export function Button({ children, color, size, ...restProps }: ButtonProps) {
  return (
    <ButtonStandard color={color} size={size} {...restProps}>
      {children}
    </ButtonStandard>
  );
}

export function ButtonCircle({ children, color, size, ...restProps }: ButtonProps) {
  return (
    <Circle color={color} size={size} {...restProps}>
      {children}
    </Circle>
  );
}