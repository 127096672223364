import React from 'react';
import SocialShareButtons from '../../components/SocialImageGenerator';
import Title from '../../components/Title';
import { systemInformation } from '../../helpers/user.helper';
import { Container } from './styles/SocialShare';

export default function SocialShare(props: any) {
  const isNotValidBrowser = systemInformation()?.browser === 'Safari';
  return (
    <Container>
      <Title>Social Image Creator</Title>
      <SocialShareButtons {...props} />
      {isNotValidBrowser && (
        <span style={{ marginTop: 20, fontSize: 'small' }}>
          Currently Safari limits capture of images, we are working on it.
        </span>
      )}
    </Container>
  );
}