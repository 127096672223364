import { toPng, toJpeg } from 'html-to-image';

export function saveAs(uri: any, filename: string) {
  let link = document.createElement('a');
  if (typeof link.download === 'string') {
    link.href = uri;
    link.download = filename;

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    //simulate click
    link.click();

    //remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
}

export const imageTypes = {
  JPG: 'JPG',
  PNG: 'PNG'
};

export async function getSocialImage(
  elementId: string,
  type = imageTypes.JPG,
  filename = 'soul-inspire-social-share',
  hidden = true,
  allowSave = true,
  callback?: Function
) {
  const element = document.getElementById(elementId)!;

  // if it's hidden, let's keep it hidden (by making sure it's way behind in the viewport), but we need to make sure it is visible in the DOM for capture
  if (hidden) {
    element.style.zIndex = '-100';
    element.style.display = 'block';
  }

  // alright let's make the image based on what imageType was passed in, JPG by default
  let imageUrl = null;
  switch (type) {
    case imageTypes.PNG:
      imageUrl = await toPng(element, {
        pixelRatio: 1
      });
      break;
    /* falls through */
    case imageTypes.JPG:
    default:
      imageUrl = await toJpeg(element, {
        pixelRatio: 1,
        quality: 1
      });
      break;
  }

  // let's honor if it was hidden before, we make it hiddent again
  if (hidden) {
    element.style.display = 'none';
  }

  // should we display a save option... if so, we have a helper for that
  allowSave && imageUrl && saveAs(imageUrl, `${filename}.${type.toLowerCase()}`);

  // execute callback if they provided one
  callback && callback();

  return imageUrl;
}
