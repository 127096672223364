import styled from 'styled-components/macro';

type ButtonProps = {
  onClick?: React.MouseEvent<HTMLButtonElement>,
  [x: string]: any,
};

export const Container = styled.header`
  height: 100px;
  margin: 5px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  h1 {
    font-weight: 100;
  }

  img, a {
    height: 100%;
    border: 0;
  }
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgba(255,255,255, 0.8);
  background: transparent;
  height: 40px;
  width: 40px;
  margin: 10px 20px 0 0;

  svg {
    color: rgba(255,255,255, 0.8);
  }

  &:hover {
    background: white;
    svg {
      color: black;
    }
    cursor: pointer;
  }

  &:active {
    border: none;
    outline: none;
  }

  &:focus {
    outline: none;
  }
`;