import styled from 'styled-components/macro';
// import colors from '../../../styles/colors';
import { ButtonProps } from './ButtonStandard';

export const ButtonCircle = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  background-image: linear-gradient(to right, #d2d2d2 0%, #2d2d2d 50%, #1a1a1a 100%);
  transition: 0.5s;
  border: none;

  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  border-radius: 15px;

  ${({ size }) => {
    switch (size) {
      case 'lg':
        return `
          width: 50px;
          height: 50px;
          padding: 10px 16px;
          font-size: 18px;
          border-radius: 25px;
        `;
      case 'xl':
        return `
          width: 70px;
          height: 70px;
          padding: 10px 16px;
          font-size: 24px;
          border-radius: 35px;
        `;
    }
  }}

  &:hover {
    background-position: right center;
  }
`;
