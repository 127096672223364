import React from 'react';
import { Quote } from '../../views/Main';
import { Button } from '../../components/Buttons';
import Spinner from '../../components/Spinner';
import { isLight } from '../../helpers/color.helper';
import { getSocialImage, imageTypes } from '../../helpers/image.helper';
import {
  Wrapper,
  Container,
  Inner,
  Header,
  Footer,
  QuoteContainer,
  Quote as Quotation,
  Author
} from './styles/SocialImageGenerator';
import colors from '../../styles/colors';
import siLogo from '../../images/logos/soul-inspire-logo-1-trans.png';

interface Social {
  quote: Quote | null,
  background: string | null,
  color: string | null,
  disabled?: boolean | null,
  [x: string]: any
}

function SocialImageGenerater({ quote, background, color, show, ...restProps }: Social) {
  const light = isLight(color || '');

  return (
    <Wrapper show={show}>
      <Container {...restProps} background={background} show={show}>
        <Inner color={color || 'black'}>
          <Header>
            <h1>{`SOUL INSPIRE {Community}`}</h1>
          </Header>
          <QuoteContainer>
            <Quotation light={light}>
              {quote ? quote.quote : ''}
            </Quotation>
            <Author light={light}>
              {quote ? `- ${quote.author}` : ''}
            </Author>
          </QuoteContainer>
          <Footer>
            <img src={siLogo} alt="Soul Inspire Logo" />
            <h1>WWW.SOULINSPIRE.CO</h1>
          </Footer>
        </Inner>
      </Container>
    </Wrapper>
  );
}

export default function SocialShareButtons({ disabled, quote, background, color }: Social) {
  const [isImageBeingGenerated, setIsImageBeingGenerated] = React.useState<Boolean>(false);

  return (
    <>
      <SocialImageGenerater id="soul-inspire-social-share" background={background} color={color} quote={quote} />
      {
        isImageBeingGenerated
          ? <Spinner
            size="6x"
            color={colors.blue}
          />
          : (
            <>
              <Button
                size="xl"
                onClick={() => {
                  setIsImageBeingGenerated(true);
                  getSocialImage(
                    'soul-inspire-social-share',
                    imageTypes.JPG,
                    'soul-inspire-social-share',
                    true,
                    true,
                    () => setIsImageBeingGenerated(false)
                  );
                }}
                disabled={isImageBeingGenerated}
              >JPG</Button>

              <Button
                size="xl"
                onClick={() => getSocialImage(
                  'soul-inspire-social-share',
                  imageTypes.PNG,
                  'soul-inspire-social-share',
                  true,
                  true,
                  () => setIsImageBeingGenerated(false)
                )}
                disabled={isImageBeingGenerated}
              >PNG</Button>
            </>
          )
      }
    </>
  );
}
