import styled from 'styled-components/macro';

type ComponentProps = {
  primary?: boolean,
  secondary?: boolean
};

export const Component = styled.hr`
  border: 0;
  height: 1px;
  background-image: linear - gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));

  ${({ primary }: ComponentProps) => primary
    ? `
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
    `
    : ''
  }

  ${({ secondary }: ComponentProps) => secondary
    ? `
      border: 0;
      height: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    `
    : ''
  }
`;