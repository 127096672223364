import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../../data/firebase';
import { useUserContext } from '../../hooks/userHook';
import Title from '../../components/Title';
import Overlay from '../../components/Overlay';
import { Input, InputPassword } from '../../components/Input';
import { Button } from '../../components/Buttons';
import { toastError } from '../../components/Toast';
import { Container } from './styles/SignIn';

export default function SignIn() {
  const overlayRef = useRef<any>(null);
  const navigate = useNavigate();
  const { setUser } = useUserContext();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const result = await signIn(email, password);
    if (result.user) {
      setUser(result.user);
      navigate('/');
    } else {
      // there was an issue
      toastError(result.message);
    }
  };

  useEffect(() => {
    overlayRef.current.show(true);
  }, []);

  return (

    <Overlay ref={overlayRef} closeCallback={() => navigate('/')} >
      <Container>
        <Title>Sign In</Title>
        <form onSubmit={handleSubmit}>
          <Input
            name="email"
            type="email"
            placeholder="Email"
            label="Login"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
            value={email}
            required
          />
          <InputPassword
            name="password"
            placeholder="Password"
            label="Password"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
            value={password}
            required
          />

          <Button>Sign In</Button>
        </form>
      </Container>
    </Overlay>
  );
}