const colors = {
  black: '#000',
  white: '#fff',
  offBlack: '#010101',
  offWhite: '#f4faf8',
  green: '#435c5f',
  lightGrey: '#868686',
  lightGreen: '#98a09e',
  blue: '#3793cb'
};

export default colors;