import { signInWithEmailAndPassword, User as FirebaseUser } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { User } from "../models";
import { auth, db } from '../utils/firebase';

type SignInResponse = {
  success: boolean;
  message: string;
  user: User;
};

enum COLLECTIONS {
  UserInfo = 'user-info',
}

export async function signIn(email: string, password: string): Promise<SignInResponse> {
  const response = await signInWithEmailAndPassword(auth, email, password)
    .then(async loginResponse => {
      return {
        success: true,
        message: 'Successfully logged in',
        user: await getUserInfo(loginResponse.user),
      };
    })
    .catch(error => ({
      success: false,
      message: 'Sorry, that login wasn\'t quite right. We are looking to add the ability to reset passwords soon!',
      techMessage: error.message,
      user: null
    }));
  return response;
  // Get additional user info
}

export async function getUserInfo(user: FirebaseUser): Promise<User> {
  const docRef = doc(db, COLLECTIONS.UserInfo, user.uid);

  const returnedUserInfo = await getDoc(docRef)
    .then((snapshot) => (snapshot.exists()
      ? { ...snapshot.data() }
      : {}
    ))
    .catch(() => ({}));

  // Build the user info retrieved from the database w/ defauolt values just in case
  const additionalUserInfo = {
    firstName: '',
    lastName: '',
    roles: ['GUEST'],
    ...returnedUserInfo,
  };

  return {
    id: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
    emailVerified: user.emailVerified,
    token: user.refreshToken,
    registeredDate: user.metadata.creationTime,
    ...additionalUserInfo,
  };
}