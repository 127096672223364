import { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../../utils/firebase';
import { User } from '../../models';
import { getUserInfo } from '../../data/firebase';

type UserContextType = {
  user: User;
  loading: boolean;
  setUser: (user: User) => void;
  logoutUser: () => void;
}

const UserContext = createContext<UserContextType>({
  user: null,
  loading: true,
  setUser: () => null,
  logoutUser: () => null
});

const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<User>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (() => {
      setLoading(true);
      // get user and their information
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          // Cool we are already logged in, get the additional info
          const currentUser = await getUserInfo(user);
          console.log('USER: current user detected...');
          setUser(currentUser);
        }
        setLoading(false);
      });
    })();

  }, []);

  const logoutUser = () => {
    auth.signOut();
    setUser(null);
  };

  const superSetUser = (newUser: User) => {
    setUser(newUser);
  };

  return (
    <UserContext.Provider value={{ user, setUser: superSetUser, logoutUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
export default UserProvider;
