import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { log } from './utils/firebase';
import UserProvider from './hooks/userHook';
import Main from './views/Main';
import './styles/default.scss';
import 'react-toastify/dist/ReactToastify.css';


const container = document.getElementById('soul-inspire-app');

// Because TypeScript can't guarantee it isn't going to be null, we need to do a check first
if (!container) throw new Error('Failed to find the root element');

const root = ReactDOMClient.createRoot(container);

root.render(
  <React.StrictMode>
    <UserProvider>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </UserProvider>
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      theme="colored"
    />
  </React.StrictMode>
);

log('notification_recieved');