import styled from 'styled-components/macro';

// ============================== MOBILE BROWSER HEIGHT FIX ==============================
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
// =======================================================================================

type ContainerProps = {
  background?: string | null
};

export const Container = styled.div`
  height: 100%;
  background: ${({ background }: ContainerProps) => background ? background : 'transparent'};
  background-size: cover;
`;

export const Inner = styled.div`
  background: rgba(0,0,0,0.7);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-self: center;

  scrollbar-color: transparent transparent;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  @media only screen and (min-width: 1000px) {
    width: 1000px;
  }
`;