import styled from 'styled-components/macro';
import { hexToRGB } from '../../../helpers/color.helper';

type ContentProps = {
  direction: string,
  anchorEl: string,
  [x: string]: any;
};

export const Container = styled.menu`
  position: relative;
  display: inline-block;
`;

export const Content = styled.div.attrs({
  className: "shadow-light"
}) <ContentProps>`

  position: absolute;
  display: ${({ show }) => show ? 'flex' : 'none'};
  flex-direction: column;
  background-color: ${hexToRGB('#f1f1f1', 0.9)};
  min-width: 160px;

  right: ${({ anchorEl }) => {
    const element = document.getElementById(anchorEl);
    return element ? `${window.innerWidth -
      (element.getBoundingClientRect().right - (element.getBoundingClientRect().width / 4))
      }px` : '20px';
  }};
  margin-top: 10px;

  animation: grow 300ms ease-in-out forwards;

  @keyframes grow {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(1.1)
    }
    100% {
        transform: scaleY(1)
    }
  }

  z-index: 1;

  ${({ direction, anchorEl }) => {
    const element = document.getElementById(anchorEl);

    if (direction === 'up') {
      return `
        bottom: ${element ? `${window.innerHeight
          - (element.getBoundingClientRect().bottom
            - (element.getBoundingClientRect().width
            ))
          }px` : '20px'};
          transform-origin: bottom center;
      `;
    }
    return `
      top: ${element ? `${element.getBoundingClientRect().y + element.getBoundingClientRect().height}px` : '20px'};
      transform-origin: top center;
      `;
  }}}
`;

const baseItemStyle = `
  padding: 12px 16px;
  display: block;
`;

export const Item = styled.div<ContentProps>`
  ${({ link }) => link ? `a { ${baseItemStyle} }` : baseItemStyle}

  width: 100%;
  color: black;
  white-space: nowrap;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    cursor: pointer;
    border-radius: 0;
    border: 1px solid black;
    padding: 3px;
  }

  a.menu-link {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
  }

  a.menu-link:hover {
    cursor: pointer;
    background: #393939;
    color: white !important;
  }

  a.menu-link, a.menu-link:visited {
    text-decoration: none;
    color: inherit;
  }

  a:visited {
    color: inherit;
  }
`;