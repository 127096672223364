import styled from 'styled-components/macro';
import { hexToRGB } from '../../../helpers/color.helper';

type ContainerProps = {
  background?: string | null,
  show?: boolean
};

export const Wrapper = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -100;
  ${({ show }: ContainerProps) => show && `z-index: 0;`}
`;

export const Container = styled.div`
  height: 1080px;
  width: 1080px;
  background: ${({ background }: ContainerProps) => background ? `url(${background}) no-repeat center center fixed` : 'transparent'};
  background-size: cover;
  border-left: 20px solid black;
  border-right: 20px solid black;
  display: none;
  ${({ show }: ContainerProps) => show && `display: block;`}
`;

type InnerProps = {
  color?: string | null,
}
export const Inner = styled.div`
  background: ${({ color }: InnerProps) => color ? hexToRGB(color, 0.9) : 'rgba(0,0,0,0.8)'};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: black;

  h1 {
    font-weight: 100;
    font-size: 300%;
    letter-spacing: 0.8rem;
    white-space: nowrap;
    padding: 5px;
  }
`;

export const Footer = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: black;

  img {
    height: 175px;
  }

  h1 {
    font-weight: 100;
    font-size: 250%;
    letter-spacing: 0.8rem;
    white-space: nowrap;
  }
`;

type LightProps = {
  light?: boolean | null;
}
export const Quote = styled.span`
  font-family: 'Shadows Into Light';
  font-weight: bold;
  font-size: 400%;
  line-height: 1.1;
  color: ${({ light }: LightProps) => light ? 'black' : '#ffffff'};
`;

export const Author = styled.span`
  font-family: 'Playfair Display';
  font-weight: 200;
  font-size: 200%;
  justify-self: flex-end;
  align-self: flex-end;
  margin-top: 15px;
  margin-right: 15px;
  white-space: nowrap;
  color: ${({ light }: LightProps) => light ? 'black' : '#ffffff'};
`;

export const QuoteContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
`;