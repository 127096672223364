import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Social from '../SocialShare';
import SignIn from '../SignIn';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Quotation from '../../components/Quotation';
import { randomNumber } from '../../helpers/number.helper';
import { Container, Inner, Content } from './styles/Main';

export interface Quote {
  quoteId: number,
  author: string,
  quote: string,
  category: string | null,
  quoteDate: string | null,
};

export interface Image {
  user: {
    name: string,
  },
  referralUrl: string,
  urls: {
    regular: string,
  },
  color: string,
};

export default function App() {
  const [image, setImage] = useState<Image | null>(null);
  const [quote, setQuote] = useState<Quote | null>(null);

  const getImages = () => {
    axios.get('https://api.soulinspire.co/v1/images')
      .then(({ data }) => {
        const selectedImage = (data.image && data.image.length) > 0
          ? data.image[randomNumber(data.image.length)]
          : null;
        selectedImage.referralUrl = 'https://unsplash.com/?utm_source=SoulInspire&utm_medium=referral';
        setImage(selectedImage);
      })
      .catch(() => {
        console.log('Houston... we have a problem...no image today!');
      });
  };

  const getQuote = () => {
    axios.get('https://api.soulinspire.co/v1/quotes/random')
      .then(({ data }) => {
        const selectedQuote = (data.quote && data.quote.length) > 0
          ? data.quote[0]
          : null;
        setQuote(selectedQuote);
      })
      .catch(() => {
        console.log('Houston... we have a problem...no quotes today!');
      });

  };

  useEffect(() => {
    getQuote();
    getImages();
  }, []);

  const imageUrl = (image && image.urls) ? image.urls.regular : null;
  const imageColor = (image && image.color) ? image.color : null;

  return (
    <Container background={`${imageUrl ? `url(${imageUrl}) no-repeat center center fixed` : null}`}>
      <Inner>
        <Header />
        <Content>
          <Routes>
            <Route path="/image-creator" element={<Social background={imageUrl} color={imageColor} quote={quote} />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/*" element={<Quotation quote={quote} color={imageColor} />} />
          </Routes>

        </Content>
        <Footer imageInfo={image} />
      </Inner>
    </Container >
  );
}
