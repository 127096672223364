
export function systemInformation() {
  const { userAgent } = navigator;
  return {
    ...getBrowserInformation(userAgent),
    ...getOperatingSystemInformation(userAgent)
  };
}

function getBrowserInformation(userAgent: string) {
  let browser = undefined;
  let browserVersion = undefined;

  if (userAgent.includes('Firefox/')) {
    browser = 'Firefox';
    browserVersion = userAgent.split('Firefox/')[1] || undefined;
  } else if (userAgent.includes('Edg/')) {
    browser = 'Edge (Chromium)';
    browserVersion = userAgent.split('Edg/')[1] || undefined;
  } else if (userAgent.includes('Chrome/')) {
    browser = 'Chrome';
    browserVersion = (userAgent.split('Chrome/')[1] || '').split(' ')[0] || undefined;
  } else if (userAgent.includes('Safari/')) {
    browser = 'Safari';
    browserVersion = userAgent.split('Safari/')[1] || undefined;
  }

  return {
    browser,
    browserVersion
  };
}

function getOperatingSystemInformation(userAgent: string) {
  const osInfoExtract = userAgent.match(/\(([^)]+)\)/);
  const osInfo = ((osInfoExtract && osInfoExtract[1]) ? osInfoExtract[1] : '').split(';');

  return {
    os: osInfo && osInfo[0],
    osInformation: osInfoExtract && osInfoExtract[1]
  };
}