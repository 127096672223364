import { StandardInput, Label } from './styles/Input';

interface InputProps {
  label?: string | null,
  [x: string]: any,
}

export function Input({ label = null, ...restProps }: InputProps) {
  return (
    <StandardInput>
      {label && <Label>{label}</Label>}
      <input type="text" {...restProps} />
    </StandardInput>
  );
};

export function InputPassword({ label = null, ...restProps }: InputProps) {
  return (
    <StandardInput>
      {label && <Label>{label}</Label>}
      <input {...restProps} type="password" />
    </StandardInput>
  );
};
