import styled from 'styled-components/macro';
import colors from '../../../styles/colors';

export const StandardInput = styled.div`
  & > input {
    display: block;
    margin: 0;
    padding: 0.8rem 1.6rem;
    color: ${colors.offBlack};
    width: 100%;
    font-family: inherit;
    font-size: 1.25rem;
    font-weight: inherit;
    line-height: 1.8;
    border: none;
    transition: box-shadow 300ms;

    &:focus {
      outline: none;
      box-shadow:
        0 5px 10px ${colors.blue};
    }

    &::placeholder {
    color: ${colors.lightGrey};
    }
  }
`;

export const Label = styled.label`
  display: block;
  color:${colors.white};
  font-family: inherit;
  font-weight: inherit;
  line-height: 1.8;
`;