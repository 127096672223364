import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore, serverTimestamp } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';

const config = {
  apiKey: "AIzaSyBEuMoBjyS8NNhbxNjUSqUnqivlK6sV2Mo",
  authDomain: "soulinspire-app.firebaseapp.com",
  projectId: "soulinspire-app",
  storageBucket: "soulinspire-app.appspot.com",
  messagingSenderId: "430707022789",
  appId: "1:430707022789:web:6a9105ffc18e16e3ce49ac",
  measurementId: "G-EZ2CVLG19C",
  timestampsInSnapshots: true,
};

const app = initializeApp(config);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const timestamp = () => serverTimestamp();
export const log = (event: any) => logEvent(analytics, event);

