import styled from 'styled-components/macro';
import { hexToRGB } from '../../../helpers/color.helper';

type ContainerProps = {
  color?: string | null
};

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 15px 25px 100px 25px;
  padding: 45px;
  background-color: ${({ color }) => color ? hexToRGB(color, 0.75) : 'transparent'};
  /* box-shadow: 0 24px 48px -12px ${({ color }) => color ? hexToRGB(color, 0.75) : 'none'}; */
  box-shadow: 0 0 10px 10px ${({ color }) => color ? hexToRGB(color, 0.75) : 'none'};

  @media only screen and (min-width: 1000px) {
    margin-bottom: 150px;
  }
`;

export const Quote = styled.span`
  font-family: 'Shadows Into Light';
  font-weight: bold;
  font-size: 2.25em;
  line-height: 1.1;
`;

export const Author = styled.span`
  margin-top: 50px;
  font-weight: 200;
  text-transform: uppercase;
  font-size: 1.25em;

  &:before {
  content: "- ";
}
`;